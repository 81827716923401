
import joaoPic from '../images/Profile/Joao.jpeg';
import davidPic from '../images/Profile/David.jpeg';

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faEnvelope,
} from '@fortawesome/free-solid-svg-icons'

import {
	faLinkedinIn,
    faGithub,
} from "@fortawesome/free-brands-svg-icons";
import PropTypes from "prop-types";

const teamMembers = [
	{
		picture: davidPic,
		fullName: "David Rodrigues",
		designation: "Co-founder",
		bio: " After concluding a MSc in Computer Science and Engineering from Instituto Superior Técnico (IST), David went on to work as a Machine Learning Engineer @Loka. Currently he is pursuing a PhD, supported by a Marie Skłodowska Curie Fellowship, and is focusing on the application of AI technologies for the development of 6G Networks.",
		socialLinks: [
			{ icon: faLinkedinIn, href: "https://www.linkedin.com/in/davidmiguelsu/" },
			{ icon: faGithub, href: "https://github.com/davidmiguelsu" },
			{ icon: faEnvelope, href: "mailto:david@olympusml.com"},
		],
	},
	{
		picture: joaoPic,
		fullName: "João Lopes",
		designation: "Co-founder",
		bio: "João holds a BSc and MSc in Computer Science and Engineering from Instituto Superior Técnico, with a specialization in AI, and previously worked as a Computer Vision Research Engineer at INESC-ID",
		socialLinks: [
			{ icon: faLinkedinIn, href: "https://www.linkedin.com/in/jclopes99/" },
			{ icon: faGithub, href: "https://github.com/swNotJoao" },
			{ icon: faEnvelope, href: "mailto:joao@olympusml.com" },
		],
	},
];

const TeamMemberItem = ({ member }) => (
	<div className="bg-white dark:bg-gray-100 shadow-xl rounded-xl hover:-translate-y-1 duration-500 h-full p-6 lg:p-8">
		<img
			src={member.picture}
			alt={member.fullName}
			className="max-w-full h-auto rounded-full border-4 p-1 border-blue-900 mx-auto -mt-20"
			width="120"
		/>
		<div className="mt-6">
			<h4 className="text-2xl font-medium mb-1">{member.fullName}</h4>
			<p className="mb-4 text-sm">{member.designation}</p>
			<p className="opacity-50">{member.bio}</p>
			<div className="mt-6">
				{member.socialLinks.map((item, i) => (
					<a
						href={item.href}
						className={`inline-block opacity-80 transition duration-300 hover:translate-y-1 hover:opacity-100 ${
							i + 1 !== member.socialLinks.length && "mr-4"
						}`}
						key={i}
					>
						<FontAwesomeIcon className='text-blue-900' icon={item.icon} />
					</a>
				))}
			</div>
		</div>
	</div>
);

TeamMemberItem.propTypes = {
	member: PropTypes.object.isRequired,
};

const Intro = () => {
	return (
		<section className="ezy__team10 light md:py-4 bg-white text-zinc-900 dark:text-[#0b1727] mb-8" id="intro">
			<div className="container px-4 mx-auto">
				<div className="flex justify-center mb-6 md:mb-12">
					<div className="max-w-lg text-center">
                        <h2 className="mt-2 mb-2 md:text-3xl text-3xl font-bold uppercase text-blue-900">
							Our Team
						</h2>
                        <div className='flex justify-center'>
                            <div className='w-40 border-b-4 border-blue-900'></div>
                        </div>
					</div>
				</div>

				<div data-aos="zoom-in" className="grid grid-cols-2 gap-10 text-center pt-6">
					{teamMembers.map((member, i) => (
						<div className="col-span-4 md:col-span-2 lg:col-span-1" key={i}>
							<TeamMemberItem member={member} />
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Intro;
