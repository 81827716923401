import React from 'react';
import img from '../images/Computer_Vision.png'; 
import img2 from '../images/Chatbot.png';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 pb-4" >
            <section >
                    <div className="py-4 ">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-36 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="my-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">We are dedicated to helping our clients grow and succeed with tailored, innovative solutions.</h2>
                    </div>

                    <div className="px-12" data-aos="fade-right" data-aos-delay="450">
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
        {/* Card 1 */}
        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group flex flex-col">
            <div className="w-full h-64 flex items-center justify-center p-2 overflow-hidden">
                <img alt="card img" className="w-full h-full object-contain group-hover:scale-[1.05] scale-[0.95] transition duration-1000 ease-in-out" src={img} />
            </div>
            <div className="m-2 flex-grow flex flex-col justify-between">
                <h2 className="font-semibold my-4 text-2xl text-center">Computer Vision Solutions</h2>
                <p className="text-md font-medium text-justify">
                    Leverage advanced computer vision to automate tasks, enhance image and video analysis, and boost efficiency. We customize solutions like object detection and facial recognition to fit your business needs.
                </p>
            </div>
        </div>

        {/* Card 2 */}
        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group flex flex-col">
            <div className="w-full h-64 flex items-center justify-center p-2 overflow-hidden">
                <img alt="card img" className="w-full h-full object-contain group-hover:scale-[1.05] scale-[0.95] transition duration-1000 ease-in-out" src={img2} />
            </div>
            <div className="m-2 flex-grow flex flex-col justify-between">
                <h2 className="font-semibold my-4 text-2xl text-center">Conversational AI Solutions</h2>
                <p className="text-md font-medium text-justify">
                    Elevate customer engagement with intelligent chatbots and virtual assistants. Our conversational AI solutions improve service efficiency, offering personalized, seamless communication across multiple platforms.
                </p>
            </div>
        </div>

        {/* Card 3 */}
        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group flex flex-col">
            <div className="w-full h-64 flex items-center justify-center p-2 overflow-hidden">
                <img alt="card img" className="w-full h-full object-contain group-hover:scale-[1.05] scale-[0.95] transition duration-1000 ease-in-out" src={img3} />
            </div>
            <div className="m-2 flex-grow flex flex-col justify-between">
                <h2 className="font-semibold my-4 text-2xl text-center">Business Process Optimization</h2>
                <p className="text-md font-medium text-justify">
                    Optimize workflows with AI-driven automation that reduces costs and boosts productivity. Our solutions help streamline operations, so you can focus on scaling your business and achieving growth.
                </p>
            </div>
        </div>

        {/* Card 4 */}
        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group flex flex-col">
            <div className="w-full h-64 flex items-center justify-center p-2 overflow-hidden">
                <img alt="card img" className="w-full h-full object-contain group-hover:scale-[1.05] scale-[0.95] transition duration-1000 ease-in-out" src={img4} />
            </div>
            <div className="m-2 flex-grow flex flex-col justify-between">
                <h2 className="font-semibold my-4 text-2xl text-center">General AI Consultations</h2>
                <p className="text-md font-medium text-justify">
                    We provide expert AI consultations to identify business gaps and areas for improvement. Our team suggests and implements tailored AI solutions to enhance efficiency, reduce costs, and drive innovation.
                </p>
            </div>
        </div>
    </div>
</div>

            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-900 
                            font-bold">We <span className='font-black'>Innovate</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                As a dynamic team with cutting-edge AI expertise, we craft innovative solutions tailored to your needs. Our fresh perspective and technical skills enable us to optimize processes, enhance efficiency, and drive your business forward.
</p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-900 font-bold">We <span className='font-black'>Partner</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                We work closely with your team to co-create scalable, AI-driven solutions or develop custom software that addresses your unique challenges, helping you streamline operations and drive growth.
</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;