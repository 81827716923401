import React from 'react';
import { motion } from 'framer-motion';

// Importing local images
import logo1 from '../images/logos/AWS.png';
import logo2 from '../images/logos/NVIDIA.svg';
import logo3 from '../images/logos/META.png';
import logo4 from '../images/logos/OPENAI.png';
import logo5 from '../images/logos/IST.png';
import logo6 from '../images/logos/HOSTINGER.png';
import logo7 from '../images/logos/GITHUB.png';
import logo8 from '../images/logos/FCUL.png';
import logo9 from '../images/logos/DOCKER.png';

const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9];

const LogoSlider = () => {
    return (
        <div className="relative w-full overflow-hidden p-5" data-aos="fade-left" data-aos-delay="100" data-border="true">
            {/*<h2 className="mt-0 mb-2 text-center text-3xl text-blue-900 uppercase font-bold">Proudly Supported By</h2>
            
            <div className='flex justify-center mb-4'>
                <div className='w-24 border-b-4 border-blue-900'></div>
            </div>

            {/* Wrapping div for seamless looping */}
            <motion.div
                className="flex"
                initial={{ x: 0 }}
                animate={{
                    x: '-100%',
                    transition: {
                        ease: 'linear',
                        duration: 640,  // Adjust duration for speed
                        repeat: Infinity,
                    }
                }}
                style={{ width: '1000%' }} // Ensures enough space for seamless looping
            >
                {/* Render logos twice in one sequence */}
                <div className="flex gap-10">
                    {logos.map((logo, index) => (
                        <div key={index} className="flex-shrink-0 flex items-center justify-center" style={{ width: '150px' }}>
                            <img src={logo} alt={`Logo ${index}`} className="h-16 object-contain" />
                        </div>
                    ))}
                    {logos.map((logo, index) => (
                        <div key={index} className="flex-shrink-0 flex items-center justify-center" style={{ width: '150px' }}>
                            <img src={logo} alt={`Logo ${index}`} className="h-16 object-contain" />
                        </div>
                    ))}
                    {logos.map((logo, index) => (
                        <div key={index} className="flex-shrink-0 flex items-center justify-center" style={{ width: '150px' }}>
                            <img src={logo} alt={`Logo ${index}`} className="h-16 object-contain" />
                        </div>
                    ))}
                    {logos.map((logo, index) => (
                        <div key={index} className="flex-shrink-0 flex items-center justify-center" style={{ width: '150px' }}>
                            <img src={logo} alt={`Logo ${index}`} className="h-16 object-contain" />
                        </div>
                    ))}
                    {logos.map((logo, index) => (
                        <div key={index} className="flex-shrink-0 flex items-center justify-center" style={{ width: '150px' }}>
                            <img src={logo} alt={`Logo ${index}`} className="h-16 object-contain" />
                        </div>
                    ))}
                    {logos.map((logo, index) => (
                        <div key={index} className="flex-shrink-0 flex items-center justify-center" style={{ width: '150px' }}>
                            <img src={logo} alt={`Logo ${index}`} className="h-16 object-contain" />
                        </div>
                    ))}
                    {logos.map((logo, index) => (
                        <div key={index} className="flex-shrink-0 flex items-center justify-center" style={{ width: '150px' }}>
                            <img src={logo} alt={`Logo ${index}`} className="h-16 object-contain" />
                        </div>
                    ))}
                </div>
            </motion.div>
        </div>
    );
};

export default LogoSlider;