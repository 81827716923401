import React from 'react';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import LogoSlider from '../components/LogoSlider';
import Contact from './Contact';


const Home = () => {
    return (
        <>
            <Hero />
            <LogoSlider />
            <Services />
            <Intro />
            {/* <Contact /> */}
            <Footer />
        </>
    )
}

export default Home;

